/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import Axios from "axios"
import "./src/styles/style.scss"
import Provider, { AuthContext } from "./src/state/provider"
import Layout from "./src/components/layout"

export const wrapRootElement = Provider

export const wrapPageElement = ({ element, props }) => (
  <AuthContext.Consumer>
    {stateStore => {
      const { state } = stateStore
      const { uri } = props
      if (state.token) {
        // set headers
        Axios.defaults.headers.common["Authorization"] = `Bearer ${state.token}`
      }
      Axios.defaults.headers.common["Accept"] = "application/json"
      return (
        <Layout {...props} pageInfo={{ slug: uri }} state={{ ...stateStore }}>
          {element}
        </Layout>
      )
    }}
  </AuthContext.Consumer>
)
