import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Navbar, Nav, Col } from "react-bootstrap"
import withState from "../util/withState"

const CustomNavbar = ({ pageInfo, sticky, state }) => {
  const {
    site: {
      siteMetadata: { siteMap },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteMap {
              slug
              displayName
              pageName
              auth
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        id="site-navbar"
        className="px-0 py-4"
        {...(sticky ? { fixed: "top" } : { sticky: "top" })}
      >
        <Col lg={2} md={2} sm={2} xs={2} className="logo px-0">
          <Link to="/" className="link-no-style">
            <Navbar.Brand as="span" id="brand-logo">
              <img src="/logo.png" id="logo-img" alt="" />
            </Navbar.Brand>
          </Link>
        </Col>

        <Col lg={7} sm={1} xs={3}  className="py-lg-3 py-sm-2 py-2 px-lg-4 px-sm-1 px-3" id="nav-col">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              as="ul"
              // className="mr-auto"
              activeKey={pageInfo && pageInfo.slug}
            >
              {siteMap.map(map => {
                let canSee = true
                if (map.auth !== undefined) {
                  if (map.auth === true && state.checkLogin() === false) {
                    canSee = false
                  }
                  if (map.auth === false && state.checkLogin() === true) {
                    canSee = false
                  }
                }

                return (
                  <div key={map.slug}>
                    {canSee ? (
                      <Nav.Item
                        as="li"
                        className={`m-lg-0 menu-item${
                          pageInfo && pageInfo.slug === map.slug
                            ? " active"
                            : ""
                        }`}
                        key={map.slug}
                      >
                        <Link as="a" to={map.slug} className="nav-link">
                          {map.displayName}
                        </Link>

                        <span />
                      </Nav.Item>
                    ) : null}
                  </div>
                )
              })}
            </Nav>
          </Navbar.Collapse>
        </Col>

      </Navbar>
    </>
  )
}

export default withState(CustomNavbar)
