import Axios from "axios"

export const onRequest = ({
  endpoint,
  params,
  method,
  onSuccess,
  onFailed,
  onAnyCase,
  suppressDefaultErrorLog,
}) => {
  Axios({
    method: method || "GET",
    url: endpoint,
    params: params || {},
  })
    .then(({ data }) => {
      if (onSuccess && onSuccess instanceof Function) {
        onSuccess(data)
      }

      if (onAnyCase && onAnyCase instanceof Function) {
        onAnyCase(data)
      }
    })
    .catch(({ response }) => {
      if (onFailed && onFailed instanceof Function) {
        if (
          typeof suppressDefaultErrorLog !== "undefined" ||
          (typeof suppressDefaultErrorLog === "boolean" &&
            suppressDefaultErrorLog === true)
        ) {
          console.error(
            ((response || {}).data || {}).errors ||
              `Error on the ${method} request to ${endpoint}`
          )
        }
        onFailed(response)
      }

      if (onAnyCase && onAnyCase instanceof Function) {
        onAnyCase(response)
      }
    })
}
