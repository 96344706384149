import store from "./store"
const STORAGE_KEY = "_MAZRUI_CAREERS"

export const getStorage = () =>
  isBrowser() && window.localStorage.getItem(STORAGE_KEY)
    ? JSON.parse(window.localStorage.getItem(STORAGE_KEY))
    : store

export const setStorage = (data, callback) => {
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
  if (callback && callback instanceof Function) {
    callback(data)
  }
}

export const clearStorage = callback => {
  setStorage({})
  callback()
}

export const isBrowser = () => typeof window !== "undefined"
