import React from "react"
import PropTypes from "prop-types"
import { faFacebookF, faInstagram, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"

// === actual component ===
const Footer = () => {
  return (
    <footer>
      <div className="bg-blue p-3 p-md-5 text-white">
        <div className="container pb-3">
          <div className="row align-items-end">
            <div className="col-md-12 text-center pb-4 footer-menu">
              <Link to="https://www.mazruiinternational.ae">mazruiinternational.ae  </Link>
              <Link to="/">mazrui.careers</Link>
            </div>
            <div className="col-6 col-md-9 footer-logo-1 text-left">
              <Link to="/">
                <img src="/logo.png" alt="Mazrui"/>
              </Link>
            </div>
            <div className="col-6 col-md-3 footer-logo-2">
                <Link to="https://www.mazruiinternational.ae">
              <img src="/logo-mint.png" alt="Mazrui"/>
              </Link>
            </div>
          </div>
          <div className="row align-items-end mt-3">
            <div className="col-md-9 pl-sm-5 mt-4">
              <p className="small ml-md-2">
                © MAZRUI INETRNATIONAL 2020. All Rights Reserved<br/>
                No part of this site may be reproduced without our written permission.
              </p>
            </div>
            <div className="col-md-3">
              <div className="media ml-md-4">
                <a href="https://www.facebook.com/mazruienergyservices/">
                  <FontAwesomeIcon icon={faFacebookF} className="text-white mr-3"/>
                </a>
                <a href="https://www.instagram.com/mazruienergyservices/">
                  <FontAwesomeIcon icon={faInstagram} className="text-white mr-3"/>
                </a>
                <a href="https://www.facebook.com/mazruienergyservices">
                  <FontAwesomeIcon icon={faLinkedinIn} className="text-white mr-3"/>
                </a>
                <a href="https://www.youtube.com/channel/UC4YQpuVEiE81NTYb1Y8eyYg">
                  <FontAwesomeIcon icon={faYoutube} className="text-white mr-3"/>
                </a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </footer>
  )
}

Footer.propTypes = {
  pageInfo: PropTypes.object,
}

Footer.defaultProps = {
  pageInfo: {},
}

export default Footer
