import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import store from "./store"
import { setStorage, getStorage, clearStorage } from "./auth"

export const AuthContext = React.createContext()

const Provider = props => {
  const {
    site: {
      siteMetadata: { apiURL },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiURL
          }
        }
      }
    `
  )

  const [state, setState] = useState({ ...store })

  useEffect(() => {
    const storage = getStorage()
    if (storage.ready !== true || !state.ready) {
      setState({
        ...storage,
        ...{ ready: true },
      })
    }
  }, [state.ready])

  const checkLogin = () => {
    return state.isLoggedIn === true
  }

  const handleLogin = data => {
    setStore({
      ...store,
      ...data,
    })
  }

  const doLogout = () => {
    clearStorage(() => {
      setState(store)
    })
  }

  const setStore = data => {
    const storage = getStorage()
    setStorage({ ...storage, ...data }, setState)
  }

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        state,
        setState: setStore,
        checkLogin,
        doLogout,
        apiURL,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
