import React, { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { Container, Row, Alert, Button, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import Navbar from "./navBar"
import { onRequest } from "../util/service"
import { Link } from "gatsby"

const Header = ({
                  sticky,
                  pageInfo,
                  state,
                  site,
                  location,
                  suppressAuthAlertsOn,
                  path,
                }) => {
  const { state: stateData, checkLogin, apiURL } = state
  const isEmailVerified = ((stateData || {}).user || {}).email_verified_at
  const [emailVerificationStatus, setEmailVerificationStatus] = useState({
    type: null,
    show: false,
    message: null,
  })
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    setEmailVerificationStatus({
      type: "warning",
      show: !isEmailVerified,
      message: null,
    })
  }, [isEmailVerified, setEmailVerificationStatus])

  const pathsToSuppressAuthAlerts = useCallback(() => {
    return (suppressAuthAlertsOn || []).map(pathname =>
      pathname.endsWith("/") ? pathname.slice(0, -1) : pathname,
    )
  }, [suppressAuthAlertsOn])

  const notificationHandler = useCallback(() => {
    onRequest({
      endpoint: `${apiURL}/email/resend`,
      onSuccess: ({ message }) => {
        setEmailVerificationStatus({
          type: "info",
          show: true,
          message,
        })
      },
      onFailed: ({ data: error }) => {
        setEmailVerificationStatus({
          type: "danger",
          show: true,
          message:
            (error || {}).message || "Error in resending verification email",
        })
      },
    })
  }, [apiURL, setEmailVerificationStatus])

  const locationPathnameNow = ((location || {}).pathname || "").endsWith("/")
    ? location.pathname.slice(0, -1)
    : location.pathname || undefined
  const currentHeaderConfiguration = ((((site || {}).siteMetadata || {}).headerConfiguration) || []).filter((site) => {
    return site.slug === path
  })[0]
  return (
    <header className={[`${(currentHeaderConfiguration || {}).collapseHeader ? 'collapse-header' : ""}`]}>
      {checkLogin() === true &&
      emailVerificationStatus.show &&
      pathsToSuppressAuthAlerts().indexOf(locationPathnameNow) === -1 ? (
        <Container fluid className={["text-center"]}>
          <Row>
            <Alert
              show={emailVerificationStatus.show}
              variant={emailVerificationStatus.type}
              style={{ width: "100%" }}
              className={"text-center"}
            >
              <p className={["text-center mb-0"]}>
                {(emailVerificationStatus || {}).message ? (
                  emailVerificationStatus.message
                ) : (
                  <>
                    An email send to registered email, please verify your
                    account. If you've not received yet,
                    <Button onClick={notificationHandler} variant="link">
                      Click here to resend
                    </Button>
                    Email verification
                  </>
                )}
              </p>
            </Alert>
          </Row>
        </Container>
      ) : null}
      <Container fluid className={["text-center"]}>
        <Row>
          <div className={["container"]}>
            <Navbar sticky={sticky} pageInfo={pageInfo}/>
          </div>
        </Row>
        {!(currentHeaderConfiguration || {}).collapseHeader && (
          <div className={["container"]}>
            <Row className={["justify-content-center banner"]}>
              <div className={["col-9 col-lg-6 col-xl-4  text-center mt-md-5 pt-3 pt-md-5 banner-search"]}>
                {
                  !(currentHeaderConfiguration || {}).disableHeaderSearch &&
                  <div className={["input-group bg-white round"]}>
                    <Form.Control
                      type="text"
                      className={["form-control round"]}
                      id="inlineFormInputGroup"
                      onChange={(e) => {
                        setSearchText(e.target.value)
                      }}
                      placeholder="Be the first to see new jobs"
                    />
                    <div className={["input-group-prepend p-1"]}>
                      <Link to={`/job-search?search=${searchText}`}>
                        <FontAwesomeIcon icon={faSearch} className={"fas mr-3"} style={{ color: "#3b5a92" }}/>
                      </Link>
                    </div>
                  </div>
                }
                <h1 className={["text-white pt-5 mt-lg-5"]} dangerouslySetInnerHTML={{
                  __html: (currentHeaderConfiguration || {}).headerText || "Working at<br/>MAZRUI!",
                }}>
                </h1>
              </div>
            </Row>
          </div>
        )}
      </Container>
    </header>
  )
}

Header.propTypes = {
  sticky: PropTypes.bool,
  pageInfo: PropTypes.object,
}

Header.defaultProps = {
  sticky: false,
  pageInfo: {},
}

export default Header
