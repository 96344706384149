import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import useSticky from "../hooks/useSticky"

const Layout = ({ children, pageInfo, state, location, path }) => {
  const { isSticky } = useSticky({ scrollHeight: 130 })

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              parentURL
              siteMap {
                slug
                headerText
                disableHeaderSearch
              }
              headerConfiguration {
                slug
                headerText
                disableHeaderSearch
                collapseHeader
              }
            }
          }
        }
      `}
      render={({ site }) => {
        const childrenWithProps = React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...{ site, state } })
          }
          return child
        })
        return (
          <>
            <Header
              sticky={isSticky}
              {...{ state, site, pageInfo, location, path }}
              suppressAuthAlertsOn={[
                "/login",
                "/register",
                "/forgot-password",
                "/password-reset",
                "/auth/verify-email",
                "/terms-and-conditions",
              ]}
            />
            <div className="site-content">{childrenWithProps}</div>
            <Footer {...{ state, site, pageInfo, location }} />
          </>
        )
      }}
    />
  )
}

export default Layout
