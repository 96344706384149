import { useEffect, useState, useRef, useCallback } from "react"

function useSticky({ scrollHeight } = { scrollHeight: 200 }) {
  const [isSticky, setSticky] = useState(false)
  const element = useRef(null)

  const handleScroll = useCallback(() => {
    window.scrollY > scrollHeight ? setSticky(true) : setSticky(false)
  }, [scrollHeight])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [handleScroll])

  return { isSticky, element }
}

export default useSticky
