import React from "react"
import { AuthContext } from "../state/provider"

export default Component => props => (
  <AuthContext.Consumer>
    {stateStore => {
      return <Component {...props} state={{ ...stateStore }} />
    }}
  </AuthContext.Consumer>
)
